<template>
  <div id="app">
    <div id="demo"></div>
  </div>
</template>
 
<script>
import Pdfh5 from 'pdfh5';
import axios from 'axios';
import {getImgToken,rentplansPdf } from '@/server';
import {getCookie} from '@/lib/utils';
  export default {
	data () {
    return {
      pdfh5: null,
      openId:getCookie('openId'),
      agreementUrl:this.$store.state.authorDetailUrl,
      token:localStorage.getItem('token')||getCookie('token'),
      contractNumber: this.$route.query.contractNumber,
    }
  },
  mounted(){
    sessionStorage.setItem('key', 0);
    let that = this;
    window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    if(that.$route.path == '/rentPlanDetail'){
        window.history.pushState('forward', null, '/rentPlanDetail');
      }
    }, false);
  },
	created(){
     getImgToken({openid:this.openId}).then(res=>{
      if(res.wx_code=='0'){
        localStorage.setItem("token", res.img_token); 
        this.token = res.img_token;
        this.getPdfUrl()
      }
    })
		
	},
	methods: {
    getPdfUrl(){
      axios.post('/wxapi/rentplans_pdf',{
        contract_number: this.contractNumber
      },{
        responseType: 'arraybuffer'
      })
      .then(res => {
        this.pdfh5 = new Pdfh5('#demo', {
          data: res.data
        });
      });
      },
    }
  }
</script>

<style>
/* @import '@/lib/pdfh5.css'; */
* {
  padding: 0;
  margin: 0;
}
html,
body,
#app {
  width: 100%;
  height: 100%;
}
</style>
